import React from "react";
import BannerHero from "../components/BannerHero";
//images
import BannerImage from "../images/3.jpg";
import Logo from "../components/navigation/Logo";
import { Page } from "../styles/Styles";

export default function PageNotFound() {
    return (
        <Page>
            <BannerHero image={BannerImage} title="404:" error="Ohh No.. Page Not Found" />
            <Logo />
        </Page>
    );
}
