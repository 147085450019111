import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//gsap plugins registered
gsap.registerPlugin(ScrollTrigger);

const Styled = styled.div`
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: url(${({ image }) => image && image}) center;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid ${(props) => props.theme.second};
    .glass {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 2.5rem;
        border-radius: 10px;
        backdrop-filter: blur(9px);
        background-color: (0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);
        @media screen and (max-width: 900px) {
            padding: 1.6rem 2.4rem;
        }
        @media screen and (max-width: 500px) {
            padding: 1.2rem 2rem;
        }
        .title,
        .large-title {
            font-family: ${(props) => props.theme.font1};
            font-size: 72px;
            font-weight: 200;
            @media screen and (max-width: 700px) {
                font-size: 60px;
            }
            @media screen and (max-width: 500px) {
                font-size: 48px;
            }
            @media screen and (max-width: 350px) {
                font-size: 40px;
            }
        }
        .red,
        .green {
            font-family: ${(props) => props.theme.font2};
            font-size: 46px;
            @media screen and (max-width: 700px) {
                font-size: 36px;
            }
            @media screen and (max-width: 500px) {
                font-size: 30px;
            }
            @media screen and (max-width: 350px) {
                font-size: 24px;
            }
        }
        .small-title {
            font-family: ${(props) => props.theme.font3};
            letter-spacing: 5px;
            font-size: 28px;
            @media screen and (max-width: 700px) {
                font-size: 26px;
            }
            @media screen and (max-width: 500px) {
                font-size: 23px;
            }
            @media screen and (max-width: 350px) {
                font-size: 18px;
            }
        }
        .little-gin {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .title,
        .small-title {
            color: ${(props) => props.theme.first};
        }
        .large-title {
            color: ${(props) => props.theme.third};
            margin: 0 15px;
            @media screen and (max-width: 700px) {
                font-size: 64px;
            }
            @media screen and (max-width: 500px) {
                font-size: 52px;
            }
            @media screen and (max-width: 350px) {
                font-size: 44px;
            }
        }
        .red {
            color: ${(props) => props.theme.pop};
        }
        .green {
            color: ${(props) => props.theme.tour};
        }
        .error {
            color: ${(props) => props.theme.pop};
            font-family: ${(props) => props.theme.font2};
            font-size: 26px;
            @media screen and (max-width: 700px) {
                font-size: 20px;
            }
            @media screen and (max-width: 500px) {
                font-size: 18px;
            }
            @media screen and (max-width: 350px) {
                font-size: 16px;
            }
        }
    }
`;

export default function Banner({ image, title, green, red, gin1, gin2, gin3, error }) {
    let banner = useRef(null);
    let glassmorph = useRef(null);

    useEffect(() => {
        gsap.to(glassmorph, {
            scrollTrigger: {
                trigger: banner,
                start: "top top",
                end: "bottom top",
                scrub: true,
            },
            top: "25%",
        });
    });

    return (
        <Styled image={image} ref={(el) => (banner = el)}>
            <div className="glass" ref={(el) => (glassmorph = el)}>
                <div className="title">{title}</div>
                <div className="red">{red}</div>
                <div className="green">{green}</div>
                <div className="error">{error}</div>
                <div className="little-gin">
                    <div className="small-title">{gin1}</div>
                    <div className="large-title">{gin2}</div>
                    <div className="small-title">{gin3}</div>
                </div>
            </div>
        </Styled>
    );
}
