import React from "react";
//import components
import HomeGallery from "../components/home/HomeGallery";
//styles
import { Page } from "../styles/Styles";

export default function Home() {
    return (
        <Page>
            <HomeGallery />
        </Page>
    );
}
