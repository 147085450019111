import { createGlobalStyle } from "styled-components";
import Fertigo from "../fonts/Fertigo_PRO.otf";
import Landmark from "../fonts/Landmark-Dimensional.otf";
import Bebas from "../fonts/Bebas-Regular.ttf";
import Hind from "../fonts/Hind-Light.ttf";

export const theme = {
    first: "#FFEED6", // Floral White
    second: "#12252B", // Jungle Green
    third: "#C7A736", // Gold Metallic
    pop: "#b95f59", // red
    tour: "#41988e", // green
    font1: "'Landmark', Helvetica",
    font2: "'Bebas', Helvetica",
    font3: "'Fertigo', Helvetica",
    font4: "'Hind', Helvetica",
};

export const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background: ${(props) => props.theme.first};
    overscroll-behavior-y: none;
    font-family: ${(props) => props.theme.font2};
    cursor: none;
    @media screen and (max-width: 1000px) {
        cursor: auto;
    }
}
a, button{
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

@font-face { 
    font-family: Landmark ; 
    src: url(${Landmark});
    font-weight: 200;
}
@font-face { 
    font-family: Bebas ; 
    src: url(${Bebas})
} 
@font-face { 
    font-family: Fertigo ; 
    src: url(${Fertigo});
} 
@font-face { 
    font-family: Hind ; 
    src: url(${Hind});
} 
`;
