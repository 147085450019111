import React, {useState} from 'react'

export const CursorContext = React.createContext();

export const CursorProvider = ({children}) => {

    const [cursorStyle, setCursorStyle] = useState('default')

    return(
        <CursorContext.Provider value={[cursorStyle, setCursorStyle]}>
            {children}
        </CursorContext.Provider>
    )
}