import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { CursorContext } from "../context/CursorContext";

const Styled = styled.div`
    @media screen and (max-width: 1025px) {
        display: none;
    }
    .default {
        .primary-cursor {
            position: fixed;
            z-index: 1000;
            overflow: hidden;
            height: 8px;
            width: 8px;
            background-color: white;
            mix-blend-mode: difference;
            border-radius: 50%;
            pointer-events: none;
            transform: translate3d(0, 0, 0);
        }
        .secondary-cursor {
            position: fixed;
            z-index: 1000;
            overflow: hidden;
            height: 50px;
            width: 50px;
            background: transparent;
            mix-blend-mode: difference;
            border-radius: 50%;
            border: 1px solid white;
            pointer-events: none;
            transform: translate3d(0, 0, 0);
        }
    }
    .hover {
        .primary-cursor {
            position: fixed;
            z-index: 1000;
            overflow: hidden;
            height: 80px;
            width: 80px;
            background-color: ${(props) => props.theme.first};
            mix-blend-mode: difference;
            border-radius: 50%;
            pointer-events: none;
            transform: translate3d(0, 0, 0);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .secondary-cursor {
            display: none;
        }
    }
`;

export default function CustomCursor() {
    const [cursorStyle] = useContext(CursorContext);
    const mainCursor = useRef(null);
    const secondCursor = useRef(null);
    const positionRef = useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destinationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1,
    });

    useEffect(() => {
        document.addEventListener(
            "mousemove",
            (event) => {
                const { clientX, clientY } = event;
                const mouseX = clientX;
                const mouseY = clientY;

                positionRef.current.mouseX = mouseX - secondCursor.current.clientWidth / 2;
                positionRef.current.mouseY = mouseY - secondCursor.current.clientHeight / 2;

                mainCursor.current.style.transform = `translate3d(
                ${mouseX - mainCursor.current.clientWidth / 2}px, 
                ${mouseY - mainCursor.current.clientHeight / 2}px, 
                0 )`;
            },
            []
        );

        return () => {};
    }, []);
    useEffect(() => {
        const followMouse = () => {
            positionRef.current.key = requestAnimationFrame(followMouse);
            const {
                mouseX,
                mouseY,
                destinationX,
                destinationY,
                distanceX,
                distanceY,
            } = positionRef.current;

            if (!destinationX | !destinationY) {
                positionRef.current.destinationX = mouseX;
                positionRef.current.destinationY = mouseY;
            } else {
                positionRef.current.distanceX = (mouseX - destinationX) * 0.07;
                positionRef.current.distanceY = (mouseY - destinationY) * 0.07;

                if (
                    Math.abs(positionRef.current.distanceX) +
                        Math.abs(positionRef.current.distance) <
                    0.1
                ) {
                    positionRef.current.destinationX = mouseX;
                    positionRef.current.destinationY = mouseY;
                } else {
                    positionRef.current.destinationX += distanceX;
                    positionRef.current.destinationY += distanceY;
                }
            }
            secondCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
        };
        followMouse();
    }, []);

    return (
        <Styled>
            <div className={cursorStyle}>
                <div className="primary-cursor" ref={mainCursor}></div>
                <div className="secondary-cursor" ref={secondCursor}></div>
            </div>
        </Styled>
    );
}
