import styled from "styled-components";
import { motion } from "framer-motion";

export const Page = styled(motion.div)`
    min-height: 100vh;
    background-color: ${(props) => props.theme.first};
    position: relative;
    z-index: 0;
`;

export const Hide = styled(motion.div)`
    overflow: hidden;
    position: relative;
`;

export const Section = styled(motion.div)`
    min-height: 50vh;
    width: 100%;
    padding: 6rem 7vw;
    @media screen and (max-width: 500px) {
        padding: 5rem 5vw;
    }
`;
