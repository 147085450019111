/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CursorContext } from "../../context/CursorContext";

const Styled = styled.div`
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    min-height: 100px;
    height: 10vh;
    background: transparent;
    justify-content: center;
    align-items: center;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${(props) => props.theme.font1};
        border: 3px solid ${(props) => props.theme.second};
        background-color: ${(props) => props.theme.first};
        color: ${(props) => props.theme.second};
        font-size: 30px;
        width: 167px;
        height: 50px;
        border-radius: 2px;
        /* @media screen and (max-width: 500px) {
            font-size: 22px;
            width: 128px;
            height: 40px;
        }
        @media screen and (max-width: 350px) {
            font-size: 16px;
            width: 100px;
        } */
    }
`;

export default function Logo() {
    const [cursorStyle, setCursorStyle] = useContext(CursorContext);

    const cursorHover = (e) => {
        e.preventDefault();
        setCursorStyle("hover");
    };
    const defaultCursor = (e) => {
        e.preventDefault();
        setCursorStyle("default");
    };

    return (
        <Styled>
            <div onMouseOver={cursorHover} onMouseLeave={defaultCursor}>
                Little Tap
            </div>
        </Styled>
    );
}
