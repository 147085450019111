import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AnimatePresence } from "framer-motion";
// Global Style
import { GlobalStyle, theme } from "./styles/GlobalStyle";
//Cursor Context
import { CursorProvider } from "./context/CursorContext";
//Pages
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
// Components
import CustomCursor from "./components/CustomCursor";

export default function App() {
    const location = useLocation();
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CursorProvider>
                <CustomCursor />
                <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/" component={Home} />
                        <Route component={PageNotFound} />
                    </Switch>
                </AnimatePresence>
            </CursorProvider>
        </ThemeProvider>
    );
}
