import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
//components
import { CursorContext } from "../context/CursorContext";

const Styled = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    padding: 0 5vw;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    #phone::before {
        content: "";
    }
    #phone:hover::before {
        content: "Contact";
    }
    #map::before {
        content: "";
    }
    #map:hover::before {
        content: "Location";
    }
`;
const StyledLink = styled(motion.a)`
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.first};
    border: 2px solid ${(props) => props.theme.second};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.theme.second};
    transition: 0.6s;
    overflow: hidden;
    i {
        position: absolute;
        font-size: 18px;
    }
    ::before {
        position: absolute;
        transition: 0.6s ease-out;
        z-index: -1;
        top: 50%;
        height: 0px;
        width: 50px;
        background-color: transparent;
        color: ${(props) => props.theme.first};
        border-top-right-radius: 10%;
        border-top-left-radius: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        padding-bottom: 15px;
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    :hover {
        overflow: visible;
        background-color: ${(props) => props.theme.second};
        color: ${(props) => props.theme.first};
    }
    :hover::before {
        top: 50%;
        height: 100px;
        background: rgb(18, 37, 43, 0.85);
        @media screen and (max-width: 500px) {
            height: 80px;
        }
    }
`;

export default function NavOptions() {
    const [cursorStyle, setCursorStyle] = useContext(CursorContext);
    const cursorHover = (e) => {
        setCursorStyle("hover");
        console.log(cursorStyle);
    };
    const defaultCursor = (e) => {
        setCursorStyle("default");
        console.log(cursorStyle);
    };
    return (
        <Styled>
            <StyledLink
                id="phone"
                href="tel:01829730101"
                whileHover={cursorHover}
                onMouseLeave={defaultCursor}
            >
                <i className="fas fa-phone"></i>
            </StyledLink>
            <StyledLink
                id="map"
                href="https://www.google.com/maps/place/Little+Tap/@53.1591861,-2.6706287,17z/data=!4m5!3m4!1s0x0:0x26bd522a23a9ad80!8m2!3d53.1591861!4d-2.6684399"
                whileHover={cursorHover}
                onMouseLeave={defaultCursor}
            >
                <i className="fas fa-map-marked-alt"></i>
            </StyledLink>
        </Styled>
    );
}
