import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import gsap from "gsap";
//import components
import { CursorContext } from "../../context/CursorContext";
import NavOptions from "../NavOptions";
//import images
import first from "../../images/1.jpg";
import second from "../../images/2.jpg";
import third from "../../images/3.jpg";
import fourth from "../../images/4.jpg";

const StyledGallery = styled.section`
    position: relative;
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
        position: absolute;
        padding: 60px 30px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${(props) => props.theme.font1};
        border: 3px solid ${(props) => props.theme.second};
        background-color: rgba(255, 238, 215, 0.8);
        color: ${(props) => props.theme.second};
        font-size: 80px;
        height: 50px;
        border-radius: 2px;
        @media screen and (max-width: 700px) {
            font-size: 60px;
            padding: 45px 25px;
        }
        @media screen and (max-width: 500px) {
            font-size: 50px;
            padding: 40px 20px;
        }
        @media screen and (max-width: 300px) {
            font-size: 42px;
            padding: 30px 15px;
        }
    }
    .title {
        position: absolute;
        top: 65%;
        width: 100%;
        height: auto;
        padding: 20px;
        h1 {
            position: relative;
            z-index: 1;
            overflow: hidden;
            height: 165px;
            @media screen and (max-width: 300px) {
                height: 140px;
            }
            .hide-text {
                position: absolute;
                z-index: 200;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                .come-visit {
                    text-transform: uppercase;
                    font-size: 40px;
                    font-family: ${(props) => props.theme.font1};
                    font-weight: 200;
                    color: ${(props) => props.theme.third};
                    @media screen and (max-width: 500px) {
                        font-size: 28px;
                    }
                    @media screen and (max-width: 300px) {
                        font-size: 22px;
                    }
                }
                .tarporley,
                .website {
                    text-transform: uppercase;
                    font-size: 18px;
                    font-family: ${(props) => props.theme.font2};
                    color: ${(props) => props.theme.first};
                    @media screen and (max-width: 500px) {
                        font-size: 14px;
                    }
                }
                .icons {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                    a {
                        font-size: 28px;
                        color: ${(props) => props.theme.first};
                        margin: 0 10px;
                        @media screen and (max-width: 500px) {
                            font-size: 22px;
                        }
                    }
                }
                .website {
                    margin-top: 30px;
                    color: ${(props) => props.theme.third};
                }
            }
        }
    }
    .image-container {
        position: relative;
        height: 100%;
        width: 100%;
        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-self: center;
        }
        .image.first {
            background-image: url(${first});
            background-position: center;
            background-size: cover;
            opacity: 1;
        }
        .image.second {
            background-image: url(${second});
            background-position: center;
            background-size: cover;
            opacity: 0;
        }
        .image.third {
            background-image: url(${third});
            background-position: center;
            background-size: cover;
            opacity: 0;
        }
        .image.fourth {
            background-image: url(${fourth});
            background-position: center;
            background-size: cover;
            opacity: 0;
        }
    }
`;

export default function HomeGallery() {
    let first = useRef(null);
    let second = useRef(null);
    let third = useRef(null);
    let fourth = useRef(null);
    let imagesTL = gsap.timeline({ repeat: -1 });

    const [cursorStyle, setCursorStyle] = useContext(CursorContext);

    const cursorHover = (e) => {
        setCursorStyle("hover");
    };
    const defaultCursor = (e) => {
        e.preventDefault();
        setCursorStyle("default");
    };
    useEffect(() => {
        imagesTL
            .to(first, { opacity: 1, duration: 4 })
            .to(first, { opacity: 0, duration: 1.5 })
            .to(second, { opacity: 1, duration: 1.5 }, "-=1.5")
            .to(second, { opacity: 1, duration: 4 })
            .to(second, { opacity: 0, duration: 1.5 })
            .to(third, { opacity: 1, duration: 1.5 }, "-=1.5")
            .to(third, { opacity: 1, duration: 4 })
            .to(third, { opacity: 0, duration: 1.5 })
            .to(fourth, { opacity: 1, duration: 1.5 }, "-=1.5")
            .to(fourth, { opacity: 1, duration: 4 })
            .to(fourth, { opacity: 0, duration: 1.5 })
            .to(first, { opacity: 1, duration: 1.5 }, "-=1.5");
    });

    return (
        <StyledGallery>
            <NavOptions />
            <div className="logo">Little Tap</div>
            <div className="title">
                <h1>
                    <span className="hide-text">
                        <span className="come-visit">Restaurant & Bar</span>
                        <span className="tarporley">69 High Street, Tarporley</span>
                        <span className="icons">
                            <motion.a
                                href="https://www.facebook.com/Littletap/"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={cursorHover}
                                onMouseLeave={defaultCursor}
                            >
                                <i className="fab fa-facebook-square"></i>
                            </motion.a>
                            <motion.a
                                href="https://www.instagram.com/littletap_cw6/"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={cursorHover}
                                onMouseLeave={defaultCursor}
                            >
                                <i className="fab fa-instagram-square"></i>
                            </motion.a>
                            <motion.a
                                href="https://twitter.com/littletap_cw6?lang=en"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={cursorHover}
                                onMouseLeave={defaultCursor}
                            >
                                <i className="fab fa-twitter-square"></i>
                            </motion.a>
                            <motion.a
                                href="https://www.tripadvisor.co.uk/Restaurant_Review-g499522-d10684721-Reviews-Little_Tap-Tarporley_Cheshire_England.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={cursorHover}
                                onMouseLeave={defaultCursor}
                            >
                                <i class="fab fa-tripadvisor"></i>
                            </motion.a>
                        </span>
                        <span className="website">** New Website Launch - Coming Soon **</span>
                    </span>
                </h1>
            </div>
            <div className="image-container">
                <div className="image first" ref={(el) => (first = el)}></div>
                <div className="image second" ref={(el) => (second = el)}></div>
                <div className="image third" ref={(el) => (third = el)}></div>
                <div className="image fourth" ref={(el) => (fourth = el)}></div>
            </div>
        </StyledGallery>
    );
}
